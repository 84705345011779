<script>
  import Logo from "static/Logo.svelte";
  export let getProps;
</script>

<style>
  .logo {
    margin: 24px 0;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
</style>

<div class="header-left">
  <a href="https://astral.ru/">
    <div class="logo">
      <Logo />
    </div>
  </a>
</div>
