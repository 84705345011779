<script>
  export let list;
</script>

<style>
  .menu-item {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding: 24px;
    justify-content: flex-start;
    text-decoration: none;
  }
  .menu-item:hover {
    text-decoration: none;
    background-color: rgba(0, 86, 214, 0.05);
    cursor: pointer;
  }
  .menu-list-icon {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
  :global(.menu > a) {
    width: 100%;
    text-decoration: none;
    color: #2e3e56;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s;
  }
  .menu-list-text {
    font-size: 18px;
    font-weight: bold;
    color: #2e3e56;
    text-decoration: none;
  }
  :global(.menu > a.active) {
    background-color: rgb(0, 86, 214);
  }
  :global(.menu > a.active:hover) {
    opacity: 1;
    background-color: rgb(0, 86, 214);
  }
  :global(.menu > a.active .menu-list-text) {
    color: #fff;
  }
  :global(.menu > a.active .menu-list-icon > svg path) {
    fill: #0074c6;
  }
</style>

<a href={list.link}>
  <div class="menu-item">
    <div class="menu-list-text">{list.title}</div>
  </div>
</a>
