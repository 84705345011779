export const validateSnils = (value, error) => {
  const snils = value.replace(/\D/g, "");

  var result = false;
  if (typeof snils === "number") {
    snils = snils.toString();
  } else if (typeof snils !== "string") {
    snils = "";
  }
  if (!snils.length) {
    return "СНИЛС пуст";
  } else if (/[^0-9]/.test(snils)) {
    return "СНИЛС может состоять только из цифр";
  } else if (snils.length !== 11) {
    return "СНИЛС может состоять только из 11 цифр";
  } else {
    var sum = 0;
    for (var i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i);
    }
    var checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
      return undefined;
    } else {
      return "Неправильное контрольное число";
    }
  }
  return result;
};

/**
 * Проверка ИНН на контрольную сумму и длину
 * @param {ИНН} value
 */
export const correctInn = value => {
  if (!value) return undefined;

  const inn = String(value);

  if (inn.length === 12) {
    const checkSum1 = (
      ((7 * inn[0] +
        2 * inn[1] +
        4 * inn[2] +
        10 * inn[3] +
        3 * inn[4] +
        5 * inn[5] +
        9 * inn[6] +
        4 * inn[7] +
        6 * inn[8] +
        8 * inn[9]) %
        11) %
      10
    ).toString();

    const checkSum2 = (
      ((3 * inn[0] +
        7 * inn[1] +
        2 * inn[2] +
        4 * inn[3] +
        10 * inn[4] +
        3 * inn[5] +
        5 * inn[6] +
        9 * inn[7] +
        4 * inn[8] +
        6 * inn[9] +
        8 * inn[10]) %
        11) %
      10
    ).toString();

    if (inn[10] === checkSum1 && inn[11] === checkSum2) {
      return undefined;
    }
  }
  return "ИНН некорректен";
};
