<script>
  import { onMount } from "svelte";
  import Main from "components/Pages/Main.svelte";
  import BackToTopButton from "components/Common/Buttons/BackToTopButton.svelte";
  import Footer from "components/Footer.svelte";
  import { trackScroll, backToTop } from "utils/scrollUtils";

  let goTopBtn;

  onMount(() => {
    goTopBtn = document.querySelector(".back_to_top");
    window.addEventListener("scroll", trackScroll(goTopBtn));
    goTopBtn.addEventListener("click", backToTop);
  });
</script>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .content {
    flex: 1 0 auto;
  }
</style>

<div class="wrapper">
  <div class="content">
    <Main />
  </div>
  <BackToTopButton />
  <Footer />
</div>

<svelte:head>
<script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(55803016, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
</script>
</svelte:head>
