<script>
  export let classes;
</script>

<style>
  .divider {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .white-divider {
    background-color: #fff;
    opacity: 0.1;
  }
</style>

<hr class="divider {classes}" />
