<script>
  import HumburgerButton from "components/Common/Buttons/HumburgerButton.svelte";
  import Divider from "components/Common/Divider.svelte";
  import MenuItem from "components/Common/MobileMenuItem.svelte";
  let MENU_LINKS = [
    {
      link: "#main",
      title: "Главная"
    },
    {
      link: "#security",
      title: "Безопасность"
    },
    {
      link: "#help",
      title: "Помощь"
    }
  ];
</script>

<style>
  .title {
    color: #333;
    padding: 15px 24px;
  }
  .menu {
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
</style>

<HumburgerButton color="#0074c6">
  <div class="title">Меню</div>
  <Divider />
  <div class="menu">
    {#each MENU_LINKS as menu, i}
      <MenuItem list={menu} />
    {/each}
  </div>
</HumburgerButton>
