import { validateSnils, correctInn } from "utils/validateUtils";

const validation = values => {
  const errors = {};
  if (correctInn(values.Inn)){
    errors.Inn = "Неправильный формат ИНН ФЛ";
  }
  if (!values.Inn) {
    errors.Inn = "Обязательно к заполнению";
  }
  if (validateSnils(values.Snils)) {
    errors.Snils = "Неправильный формат СНИЛС";
  }
  if (!values.Snils) {
    errors.Snils = "Обязательно к заполнению";
  }
  return errors;
};
export default validation;
