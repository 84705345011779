<script>
  import moment from "moment";
  import Divider from "components/Common/Divider.svelte";
  import EPIcon from "static/EPIcon.svelte";
  import OrganizationIcon from "static/OrganizationIcon.svelte";
  export let certificates = [];
  export let organizations = [];
  let certificatesSize = certificates.length - 1;
  let organizationsSize = organizations.length - 1;
</script>

<style>
  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    max-height: 295px;
  }
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-right: 12px;
  }
  .list-container:not(:last-child) {
    margin-bottom: 24px;
  }
  .icon {
    margin-right: 24px;
  }
  .list-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 12px;
  }

  .info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .name {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0074c6;
  }
  .addition-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .addition-info:not(:last-child) {
    margin-bottom: 4px;
  }
  .addition-info:last-child {
    align-items: flex-end;
    text-align: right;
  }
  .addition-info.cert:last-child {
    width: 100%;
  }
  .addition-info:first-child {
    align-items: flex-start;
    text-align: left;
  }
  .info {
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #b7c2ce;
    margin-bottom: 4px;
  }
  .addition-info:last-child .info {
    text-align: right;
  }
  .addition-info:first-child .info {
    text-align: left;
  }
  .divider-wrapper {
    width: 100%;
    padding-left: 56px;
  }
  .form-link {
    color: #1976d2;
    border-radius: 8px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 11px;
    padding: 8px 12px;
  }
  .form-link:hover {
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.08);
  }
  .info-header {
    color: #072d57;
    margin-bottom: 8px;
  }
  :global(.icon.success > svg path) {
    fill: #1cad1c;
  }
  :global(.icon.error > svg path) {
    fill: #f24646;
  }
  :global(.info.status.success) {
    color: #1cad1c;
  }
  :global(.info.status.error) {
    color: #f24646;
  }
  .info.status {
    font-size: 12px;
  }
</style>

<div class="list">
  {#if certificates.length}
    {#each certificates as { startDate }, i}
      <div class="list-container">
        <div class="list-wrapper">
          <div class="icon">
            <EPIcon />
          </div>
          <div class="info-wrapper">
            <div class="addition-info cert">
              <div class="info info-header">Сертификат</div>
              <div class="info">
                Дата выдачи: {moment(startDate).format('DD.MM.YYYY')}
              </div>
            </div>
            <div class="addition-info cert">
              <div class="info">
                <a
                  href="https://forms.gle/KABg5hBr9Tk2VJW49"
                  class="form-link"
                  target="_blank">
                  Вы не получали этот сертификат?
                </a>
              </div>
            </div>
          </div>
        </div>
        {#if certificatesSize !== i || !!organizations.length}
          <div class="divider-wrapper">
            <Divider />
          </div>
        {/if}
      </div>
    {/each}
  {/if}
  {#if organizations.length}
    {#each organizations as { name, status, registrationDate }, i}
      <div class="list-container">
        <div class="list-wrapper">
          <div class="icon {status === 'Действующее*' ? 'success' : 'error'}">
            <OrganizationIcon />
          </div>
          <div class="info-wrapper">
            <div class="addition-info">
              <div class="info info-header">{name}</div>
              <div class="info">Дата регистрации: {registrationDate}</div>
              <div class="addition-info">
                <div
                  class="info status {status === 'Действующее*' ? 'success' : 'error'}">
                  {status}
                </div>
              </div>
            </div>
          </div>
        </div>
        {#if organizationsSize !== i}
          <div class="divider-wrapper">
            <Divider />
          </div>
        {/if}
      </div>
    {/each}
  {/if}
</div>
