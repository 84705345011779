<script>
  import { onMount } from "svelte";
  import { createForm } from "final-form";
  import axios from "axios";
  import ContentWrapper from "components/ContentWrapper.svelte";
  import registerField from "components/Main/MainComponent/registeredFields";
  import validation from "components/Main/MainComponent/validation";
  import Header from "components/Header.svelte";
  import ActionsCard from "./MainComponent/ActionsCard.svelte";
  import InfoCard from "./MainComponent/InfoCard.svelte";

  let hasData = false;
  let certificates = [];
  let organizations = [];
  const toggleCard = () => {
    hasData = !hasData;
    if (!hasData) {
      certificates = [];
      organizations = [];
    }
  };

  const onSubmit = async values => {
    const { Inn, Snils } = values;
    const result = await axios.get(
      "https://anti-fraud.astral-tech.ru/api/Search",
      {
        params: {
          Inn,
          Snils
        }
      }
    );
    const { data } = result;
    certificates = data.certificates;
    organizations = data.organizations;
    toggleCard();
  };

  const form = createForm({
    onSubmit,
    initialValues: {
      FirstName: "",
      LastName: "",
      Patronymic: "",
      Snils: ""
    },
    validate: validation
  });

  const format = (value, pattern) => {
    let i = 0,
      v = value.toString();
    return pattern.replace(/#/g, _ => v[i++]);
  };

  onMount(() => {
    Array.from(document.forms[0]).forEach(input => {
      if (input.name) {
        registerField(input, form);
      }
    });
    document.getElementById("form").addEventListener("submit", event => {
      event.preventDefault();
      form.submit();
    });
  });
</script>

<style>
  .top-wrapper {
    min-height: 100vh;
    background: #ebeef1 url("BodyBackground.svg") right top no-repeat;
    background-size: contain;
  }
  .content {
    display: flex;
  }
  .container {
    display: flex;
    justify-content: flex-start;
    background: url("/MainBackground.svg") right no-repeat;
    background-size: contain;
    max-width: 1600px;
    margin: auto;
  }
  .wrapper {
    display: flex;
    width: 100%;
  }
  .content-wrapper {
    margin: 52px 0;
    flex: 0 0 40%;
    width: 60%;
  }
  .title {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #072d57;
    margin-bottom: 24px;
  }
  .text {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #1d3f66;
    margin-bottom: 44px;
  }
  .visible {
    visibility: visible;
    height: auto;
  }
  .hidden {
    visibility: hidden;
    height: 0;
  }
  @media (max-width: 1399px) {
    .container {
      background-size: unset;
    }
    .top-wrapper {
      background-size: unset;
    }
  }
  @media (max-width: 991px) {
    .title {
      margin: 12px 0;
      padding: 0 36px;
    }
    .text {
      padding: 0 36px;
    }
    .content-wrapper {
      margin: 24px 0;
      flex: 0 0 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .container {
      background: none;
    }
  }
  @media (max-width: 767px) {
    .title {
      margin: 12px 0;
      padding: 0 36px;
    }
    .text {
      padding: 0 36px;
      margin-bottom: 24px;
    }
    .content-wrapper {
      margin: 24px 0;
      flex: 0 0 100%;
      width: 100%;
      text-align: center;
    }
    .container {
      background: none;
    }
  }
</style>

<div class="top-wrapper">
  <Header />
  <div class="container">
    <div class="wrapper max-width">
      <ContentWrapper>
        <div class="content">
          <div class="content-wrapper">
            <h2 class="title" id="main">
              Электронная подпись&nbsp;– не&nbsp;дай себя обмануть
            </h2>
            <div class="text">
              Портал для&nbsp;борьбы с&nbsp;мошенничеством, связанным
              с&nbsp;получением электронно-цифровой подписи незаконным путем.
            </div>
            <form id="form">
              <div class={!hasData ? 'visible' : 'hidden'}>
                <ActionsCard />
              </div>
            </form>
            <div class={hasData ? 'visible' : 'hidden'}>
              <InfoCard {toggleCard} {certificates} {organizations} />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  </div>
</div>
