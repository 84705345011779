<script>
  import { onMount } from "svelte";
  export let color;
  const HamburgerService = () => {
    let hamburger = {
      navToggle: document.querySelector(".nav-toggle"),
      background: document.querySelector(".background"),
      nav: document.querySelector(".nav"),
      navItem: document.querySelectorAll(".nav .menu a"),
      doToggle: function(e) {
        this.background.classList.toggle("expanded");
        this.navToggle.classList.toggle("expanded");
        this.nav.classList.toggle("expanded");
      }
    };
    hamburger.navItem.forEach(el => {
      el.addEventListener("click", function(e) {
        hamburger.doToggle(e);
      });
    });
    hamburger.background.addEventListener("click", function(e) {
      hamburger.doToggle(e);
    });
    hamburger.navToggle.addEventListener("click", function(e) {
      hamburger.doToggle(e);
    });
  };
  onMount(() => {
    HamburgerService();
  });
</script>

<style>
  .background {
    display: none;
  }
  .nav {
    display: none;
    box-shadow: 0px 16px 56px rgba(0, 0, 0, 0.1);
    -webkit-transition: right 0.25s ease;
    -moz-transition: right 0.25s ease;
    -ms-transition: right 0.25s ease;
    -o-transition: right 0.25s ease;
    transition: right 0.25s ease;
    background: #fff;
    color: white;
    font-size: 2rem;
    height: 100vh;
    right: -300px;
    position: fixed;
    top: 0;
    width: 300px;
    z-index: 1551;
  }

  :global(.nav.expanded) {
    right: 0;
  }

  .nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .nav-toggle {
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    height: 24px;
    width: 24px;
    z-index: 1552;
  }

  .nav-toggle:hover {
    opacity: 0.8;
  }

  .nav-toggle .nav-toggle-bar,
  .nav-toggle .nav-toggle-bar::after,
  .nav-toggle .nav-toggle-bar::before {
    position: absolute;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    background: var(--color);
    content: "";
    height: 2px;
    width: 20px;
  }

  .nav-toggle .nav-toggle-bar {
    margin-top: 0;
  }

  .nav-toggle .nav-toggle-bar::after {
    margin-top: 6px;
  }

  .nav-toggle .nav-toggle-bar::before {
    margin-top: -6px;
  }

  :global(.nav-toggle.expanded .nav-toggle-bar) {
    background: transparent !important;
  }

  :global(.nav-toggle.expanded .nav-toggle-bar::after, .nav-toggle.expanded
      .nav-toggle-bar::before) {
    background: #0056d6 !important;
    margin-top: 0 !important;
  }
  :global(.nav-toggle.expanded .nav-toggle-bar::after) {
    -ms-transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
  }

  :global(.nav-toggle.expanded .nav-toggle-bar::before) {
    -ms-transform: rotate(-45deg) !important;
    -webkit-transform: rotate(-45deg) !important ;
    transform: rotate(-45deg) !important;
  }
  .list {
    width: 100%;
  }
  @media (max-width: 767px) {
    :global(.background.expanded) {
      display: block;
      position: fixed;
      z-index: 1550;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
    .nav {
      display: flex;
    }
    .nav-toggle {
      display: flex;
      align-items: center;
    }
  }
</style>

<div class="background" />
<div class="nav-toggle">
  <div class="nav-toggle-bar" style="--color: {color}" />
</div>
<nav class="nav">
  <ul class="list">
    <slot />
  </ul>
</nav>
