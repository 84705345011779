<script>
  import { link } from "svelte-routing";
  import HumburgerWrapper from "./HumburgerWrapper.svelte";

  export let getProps;
</script>

<style>
  .header-right {
    display: flex;
    align-items: center;
    padding-top: 0;
  }
  nav > :global(a) {
    text-decoration: none;
    color: #0074c6;
    padding: 0 32px;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.9;
    transition: 0.2s;
  }
  nav > :global(a:hover) {
    opacity: 1;
  }
  @media (max-width: 767px) {
    nav {
      display: none;
    }
  }
</style>

<div class="header-right">
  <HumburgerWrapper {getProps} />
  <nav>
    <a href="#main">Главная</a>
    <a href="#security">Безопасность</a>
    <a href="#help">Помощь</a>
  </nav>
</div>
