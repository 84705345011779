import Inputmask from "inputmask";

const registered = {};

const registerField = (input, form) => {
  const { name } = input;
  form.registerField(
    name,
    fieldState => {
      const { blur, change, error, focus, touched, value } = fieldState;
      const errorElement = document.getElementById(name + "_error");
      if (!registered[name]) {
        input.addEventListener("blur", () => blur());
        input.addEventListener("input", event => {
          const caret = event.target.selectionStart;
          const element = event.target;
          window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
          });
          change(
            input.type === "checkbox"
              ? event.target.checked
              : event.target.value.trim()
          );
        });
        input.addEventListener("focus", () => focus());
        registered[name] = true;
      }
      if (input.type === "checkbox") {
        input.checked = value;
      } else {
        if (name === "Snils") {
          Inputmask({
            mask: "999-999-999 99",
            showMaskOnHover: false,
            showMaskOnFocus: false
          }).mask(input);
        } else {
          input.value = value === undefined ? "" : value;
        }
      }

      // show/hide errors
      if (errorElement) {
        if (touched && error) {
          input.classList.add("error-field");
          errorElement.innerHTML = error;
          errorElement.style.display = "block";
        } else {
          input.classList.remove("error-field");
          errorElement.innerHTML = "";
          errorElement.style.display = "none";
        }
      }
    },
    {
      value: true,
      error: true,
      touched: true
    }
  );
};

export default registerField;
