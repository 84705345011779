<script>
  import MainComponent from "../Main/MainComponent.svelte";
  import SecurityComponent from "../Main/SecurityComponent.svelte";
  import HelpComponent from "../Main/HelpComponent.svelte";
  window.scrollTo(0, 0);
</script>

<main>
  <div class="container w-100">
    <MainComponent />
    <SecurityComponent />
    <HelpComponent />
  </div>
</main>
