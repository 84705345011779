<script>
  import Button from "components/Common/Buttons/Button.svelte";
  import List from "components/Common/List.svelte";
  import ArrowBack from "static/ArrowToBack.svelte";
  import PlaceholderBackground from "static/PlaceholderBackground.svelte";

  export let toggleCard;
  export let certificates = [];
  export let organizations = [];
</script>

<style>
  .card {
    height: 420px;
    width: 555px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
  }
  .card-wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }
  .go-to-back-button {
    display: flex;
    align-items: center;
    color: #0056d6;
    opacity: 0.8;
    margin-bottom: 12px;
  }
  .go-to-back-button:hover {
    cursor: pointer;
    opacity: 1;
  }
  .arrow-back-icon {
    width: 24px;
    height: 22px;
    margin-right: 8px;
  }
  :global(.arrow-back-icon > svg) {
    fill: #0056d6;
    width: 20px;
    height: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
    color: #1d3f66;
    text-align: left;
  }
  .text {
    font-size: 18px;
    line-height: 21px;
    color: #072d57;
    margin-bottom: 12px;
    text-align: left;
  }
  .placeholder-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .placeholder-container {
    width: 44%;
  }
  .placeholder-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    color: #1d3f66;
    margin-bottom: 48px;
  }
  .placeholder-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #99a9ba;
    margin-bottom: 48px;
  }
  @media (max-width: 991px) {
    .card {
      margin: auto;
    }
    .placeholder-wrapper {
      justify-content: space-between;
    }
    .placeholder-container {
      width: 100%;
    }
    .placeholder-title {
      margin-bottom: 12px;
    }
    .placeholder-text {
      margin-bottom: 12px;
    }
  }
  @media (max-width: 767px) {
    .card {
      margin: 0;
      width: 470px;
    }
    .placeholder-wrapper {
      flex-direction: column-reverse;
    }
    .placeholder-container {
      width: 100%;
    }
    .placeholder-title {
      margin-bottom: 12px;
    }
    .placeholder-text {
      margin-bottom: 12px;
    }
    .card-wrapper {
      padding: 20px;
    }
  }
</style>

<div class="card">
  <div class="card-wrapper">
    {#if !certificates.length && !organizations.length}
      <div class="placeholder-wrapper">
        <div class="placeholder-container">
          <div class="placeholder-title">
            Электронных&nbsp;подписей и&nbsp;зарегистрированных организаций
            не&nbsp;найдено.
          </div>
          <div class="placeholder-text ">
            Вы можете вернуться к&nbsp;предыдущему экрану для&nbsp;повтора
            запроса.
          </div>
          <Button onClick={toggleCard}>Назад</Button>
        </div>
        <div>
          <PlaceholderBackground />
        </div>
      </div>
    {:else}
      <div class="go-to-back-button" on:click={toggleCard}>
        <div class="arrow-back-icon">
          <ArrowBack />
        </div>
        <div>Назад</div>
      </div>
      <div class="title">Внимание!</div>
      <div class="text">
        На&nbsp;Вас зарегистрировано {organizations.length}&nbsp;{!!(organizations.length - 1) ? 'организаций' : 'организация'}
        и&nbsp;оформлено {certificates.length}&nbsp;ЭП!
      </div>
      <List {certificates} {organizations} />
    {/if}
  </div>
</div>
